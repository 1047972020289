

import {defineComponent, onMounted, ref, onUnmounted, reactive, computed, watchEffect} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import {l} from "@/core/helpers/lang"
import {api_url, base_url} from "@/ayar";
import post from "@/core/functions/post";
import _ from "lodash"
import store from "@/store";
import toast from "@/core/functions/toast";

export default defineComponent({
  name: "PacketGroups",
  data() {
    return {
      packets: [],
      seciliUrunler: [],
      selected: {},
      musteri_urun_secer : computed(()=>store.getters.getGeneralSettingsList.musteri_urun_secer),
      birim : 0,
      number : 0,
      max : 6,
      progress : 0,
    }
  },
  methods: {
    openUrunler(packetId) {
      this.seciliUrunler = [];
      this.selected = {};

      post(api_url + 'user-paket-secili-urunler', {
        packetId: packetId,
      }).then(res => {
        res = res.data;
        if (res['durum'] === true) {
          this.seciliUrunler = res['mesaj']
        }
      });      
    },
    minus ( urunId )
    {
      if ( typeof this.selected[ urunId ] !== 'undefined' )
      {
        if ( this.selected[ urunId ] !== 0 )
        {
          this.selected[urunId] = --this.selected[ urunId ]
        }
      }
    },
    plus ( urunId )
    {
      if ( typeof this.selected[ urunId ] !== 'undefined' )
      {
        this.selected[urunId]=++this.selected[urunId]
      }
      else
      {
        this.selected[urunId] = 1
      }
    },
    addCart  ( paket )
    {
      if ( this.musteri_urun_secer === 1 )
      {
        if ( _.size(this.selected) === 0 )
        {
          toast ( `Minimum ${ paket.min_pro_count } ürün seçebilirsiniz`, 0 );
          return false;
        }
        const total = _.reduce(this.selected, (a, b)=> a+b, 0);
        if ( total >= paket.min_pro_count )
        {
          post ( base_url + 'user/packet/addCart', {
            paketId : paket.id,
            selected: this.selected,
          } ).then ( res =>
          {
            res = res.data;
            if ( res['durum'] === true )
            {
              toast ( 'Sepete eklendi. Şimdi yönlendiriliyorsunuz' ).then ( () =>
              {
                setTimeout ( () =>
                {
                  window.location.href = base_url + 'checkout';
                } );
              } );
            }
          } );
        }
        else
        {
          toast ( `Minimum ${ paket.min_pro_count } ürün seçebilirsiniz`, 0 );
        }
      }
      else
      {
        post ( base_url + 'user/packet/addCart', { paketId: paket.id } ).then ( res =>
        {
          res = res.data;
          if ( res['durum'] === true )
          {
            toast ( 'Sepete eklendi. Şimdi yönlendiriliyorsunuz' ).then ( () =>
            {
              setTimeout ( () =>
              {
                window.location.href = base_url + 'checkout';
              } );
            } );
          }
        } );
      }
    },
    increase(birim,max,index){
      // Birim fiyatı için
      if(max*birim>this.number){
        this.birim+=birim;
        this.number+=1;
      }
    }
  },
  setup() {
    const getSize = (items) => {
      return _.size(items);
    }
    setCurrentPageTitle(l('admin:transfer:packet_groups'));
    return {
      l,
      getSize,
      base_url
    }
  },
  mounted() {
    post(api_url + 'packets', {}).then((res) => {
      this.packets = res.data.mesaj;
    })
  }
});
